<template>
  <div>
    <treeContainer>
      <template v-slot:tree>
        <el-input
          class="u-bottom-margin--2x"
          :placeholder="$t('regionsPageRegionFilterLabel')"
          v-model="filterText"
        >
        </el-input>

        <el-button
          type="primary"
          size="mini"
          @click="openAddDialog('big', $event)"
          >{{ $t("regionsPageAddRegionButtonLabel") }}</el-button
        >
        <el-button
          type="info"
          class="regions-page__float-button"
          size="mini"
          @click="exportRegions('big', $event)"
          >{{ $t("regionsPageExportRegionsButtonLabel") }}</el-button
        >

        <el-tree
          :data="regionsList"
          class="u-top-margin--2x"
          node-key="id"
          @node-click="reigonClicked"
          :props="defaultProps"
          :expand-on-click-node="false"
          :filter-node-method="filterNode"
          ref="tree"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            {{ node.label }}
            <div>
              <el-button
                class="u-font--bold"
                v-if="node.level == 1"
                type="text"
                size="mini"
                @click.stop="openAddDialog(data)"
                >{{ $t("regionsPageAddRegionButtonLabel") }}</el-button
              >

              <el-button
                class="u-font--bold"
                type="text"
                size="mini"
                @click.stop="openEditDialog(data)"
                >{{ $t("regionsPageEditButtonLabel") }}</el-button
              >
            </div>
          </span>
        </el-tree>
      </template>

      <template v-slot:tree-page>
        <template v-if="selectedRegion.name">
          <div class="header u-font--super">
            {{ $t("regionsPageTableRegionLabel") }}: {{ selectedRegion.name }}
          </div>
          <el-button
            type="info"
            class="regions-page__float-button"
            size="normal"
            @click="exportAreas"
            >{{ $t("regionsPageExportAreasButtonLabel") }}</el-button
          >

          <div class="area-title-container">
            <el-button
              v-if="selectedRegion.name"
              :class="
                $store.state.isRTL ? 'u-left-margin--3x' : 'u-right-margin--3x'
              "
              type="primary"
              icon="plus"
              size="default"
              @click="openAddArea"
            >
              {{ $t("regionsPageTableAddButtonLabel") }}</el-button
            >

            <el-input
              :placeholder="$t('regionsPageTableAreaFilterLabel')"
              v-model="areaFilter.name"
              @change="filterChanged"
            >
            </el-input>
          </div>
          <el-table class="u-top-margin--2x" :data="areaData" border stripe>
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <el-table-column
              :label="$t('regionsPageTableColumnId')"
              prop="id"
              width="55"
            >
            </el-table-column>
            <el-table-column
              :label="$t('regionsPageTableColumnAreaName')"
              prop="name"
            >
            </el-table-column>
            <el-table-column
              :label="$t('regionsPageTableColumnMinPerOrder')"
              width="150"
              prop="minPerOrder"
            >
            </el-table-column>
            <el-table-column :label="$t('regionsPageTableColumnAction')">
              <template slot-scope="scope">
                <div style="display: flex">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="openEditArea(scope.row)"
                    >{{ $t("regionsPageTableEditAreaButton") }}</el-button
                  >
                  <el-button
                    type="danger"
                    size="mini"
                    @click="deleteArea(scope.row.id)"
                    >{{ $t("regionsPageTableDeleteAreaButton") }}</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <template v-else>
          <div class="msg-page u-font--super">
            {{ $t("regionsPageNoDataSelectedMessage") }}
          </div>
        </template>
      </template>
    </treeContainer>

    <el-dialog
      :title="$t('regionsPageCreateRegionDialogTitle')"
      :visible.sync="addRegionDialog"
    >
      <el-form :model="addRegion">
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelName')"
          label-width="150px"
        >
          <el-input v-model="addRegion.region" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelPointsFormula')"
          label-width="150px"
        >
          <el-input
            v-model="addRegion.config.pointsFormula"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addRegionDialog = false">{{
          $t("regionsPageDialogCancelButtonLabel")
        }}</el-button>
        <el-button type="primary" @click="createRegion">{{
          $t("regionsPageDialogAddButtonLabel")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('regionsPageEditRegionDialogTitle')"
      :visible.sync="editRegionDialog"
    >
      <el-form :model="addRegion">
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelName')"
          label-width="150px"
        >
          <el-input v-model="addRegion.region" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelPointsFormula')"
          label-width="150px"
        >
          <el-input
            v-model="addRegion.config.pointsFormula"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editRegionDialog = false">{{
          $t("regionsPageDialogCancelButtonLabel")
        }}</el-button>
        <el-button type="primary" @click="editRegion">{{
          $t("regionsPageDialogEditButtonLabel")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('regionsPageCreateAreaDialogTitle')"
      :visible.sync="addAreaDialog"
    >
      <el-form :model="addArea">
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelName')"
          label-width="150px"
        >
          <el-input v-model="addArea.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelMinPerOrder')"
          label-width="150px"
        >
          <el-input v-model="addArea.minPerOrder" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addAreaDialog = false">{{
          $t("regionsPageDialogCancelButtonLabel")
        }}</el-button>
        <el-button type="primary" @click="createArea">{{
          $t("regionsPageDialogAddButtonLabel")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :title="$t('regionsPageEditAreaDialogTitle')"
      :visible.sync="editAreaDialog"
    >
      <el-form :model="addRegion">
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelName')"
          label-width="150px"
        >
          <el-input v-model="addArea.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('regionsPageDialogFieldLabelMinPerOrder')"
          label-width="150px"
        >
          <el-input v-model="addArea.minPerOrder" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editAreaDialog = false">{{
          $t("regionsPageDialogCancelButtonLabel")
        }}</el-button>
        <el-button type="success" @click="editArea">{{
          $t("regionsPageDialogSaveButtonLabel")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import treeContainer from "@/Containers/treeContainer.vue";
import { regionsService } from "../../services/regions.service";
export default {
  components: { treeContainer },
  data() {
    return {
      addRegionDialog: false,
      editRegionDialog: false,
      addAreaDialog: false,
      editAreaDialog: false,
      addRegion: {
        config: {},
        region: "",
      },
      areaFilter: {},
      selectedRegion: {},
      addArea: {},
      regionsList: [],
      filterText: "",
      defaultProps: {
        children: "childs",
        label: "name",
      },

      areaData: [],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  async created() {
    await this.getRegions();
    await this.getAreas();
  },

  methods: {
    async exportRegions() {
      this.$loading();
      try {
        let regionsResponse = await regionsService.getRegionsCsv();
        this.downloadCSV(regionsResponse.data, "Regions");
        // window.open(regionsResponse.data);
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    async exportAreas() {
      this.$loading();
      try {
        let regionsResponse = await regionsService.getareas({
          filter: this.areaFilter,
          format: "csv",
        });
        console.log(regionsResponse);
        this.downloadCSV(
          regionsResponse.data,
          `${
            this.selectedRegion.name ? this.selectedRegion.name + ":" : ""
          }Areas`
        );
        // window.open(regionsResponse.data);
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.$loading().close();
      }
    },
    downloadCSV(csvStr, name) {
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvStr);
      hiddenElement.target = "_blank";
      hiddenElement.download = `${name}.csv`;
      hiddenElement.click();
    },
    async getRegions() {
      try {
        // TODO REMOVE STATIC FIX FROM HERE
        let regionsResponse = await regionsService.getRegions();
        this.regionsList = regionsResponse.data.result.map((r) => {
          if (r.config == undefined) r.config = {};

          r.childs.map((smallR) => {
            if (smallR.config == undefined) smallR.config = {};
          });
          return r;
        });
        return regionsResponse;
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
    openAddDialog(data) {
      this.addRegion = { config: {}, region: "" };
      if (data != "big") this.addRegion.parent_id = data.id;
      this.addRegionDialog = true;
    },
    openAddArea() {
      this.addArea = {};
      this.addAreaDialog = true;
    },
    openEditArea(data) {
      console.log(data);
      this.addArea = data;
      this.editAreaDialog = true;
    },
    openEditDialog(data) {
      this.addRegion.region = data.name;
      this.addRegion.id = data.id;

      this.addRegion.config = data.config;
      console.log(this.addRegion);
      this.editRegionDialog = true;
    },
    async createRegion() {
      try {
        await regionsService.createRegion(this.addRegion);
        await this.getRegions();
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.addRegionDialog = false;
      }
    },
    async editRegion() {
      try {
        await regionsService.editRegion({
          id: this.addRegion.id,
          data: this.addRegion,
        });
        await this.getRegions();
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.editRegionDialog = false;
      }
    },

    async reigonClicked(node) {
      this.selectedRegion = node;
      this.areaFilter.regionId = node.id;
      await this.getAreas();
      //TODO Region Clicked
    },
    async getAreas() {
      this.$loading();
      try {
        let regionsResponse = await regionsService.getareas({
          filter: this.areaFilter,
        });

        this.areaData = regionsResponse.data.areas;
        return regionsResponse;
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
        return err;
      } finally {
        this.$loading().close();
      }
    },
    async createArea() {
      this.addArea.regionId = this.selectedRegion.id;
      try {
        await regionsService.createArea(this.addArea);
        await this.getAreas();
        this.addAreaDialog = false;
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.addRegionDialog = false;
      }
    },
    async editArea() {
      try {
        await regionsService.editArea({
          id: this.addArea.id,
          data: {
            name: this.addArea.name,
            regionId: this.addArea.regionId,
            minPerOrder: this.addArea.minPerOrder,
          },
        });
        this.getAreas();
      } catch (err) {
        console.log("******************ERORR*********************");
        console.log(err);
      } finally {
        this.editAreaDialog = false;
      }
    },
    async deleteArea(id) {
      this.$confirm(this.$t("regionsPagePopupMessage"), "Warning", {
        confirmButtonText: this.$t("regionsPagePopupOkButtonLabel"),
        cancelButtonText: this.$t("regionsPagePopupCancelButtonLabel"),
        type: "warning",
      })
        .then(async () => {
          try {
            await regionsService.deleteArea(id);

            await this.getAreas();
          } catch (err) {
            console.log("******************ERORR*********************");
            console.log(err);
          }
        })
        .catch(() => {});
    },
    async filterChanged() {
      await this.getAreas();
    },
  },
  computed: {
    isRTL() {
      return this.$store.state.isRTL;
    },
  },
};
</script>

<style lang="scss" scoped>
.area-title-container {
  display: flex;
  .el-input {
    width: 400px;
  }
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  // padding-right: 4px;
  padding: 0 4px;
}

.msg-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
}
.regions-page__float-button {
  float: right;
}
[dir="rtl"] {
  .regions-page__float-button {
    float: left;
  }
}
[dir="rtl"] {
  .regions-page__float-button {
    float: left;
  }
}
</style>
