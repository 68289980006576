<template>
  <el-container>
    <el-aside
      :width="asideWidth"
      style="min-height:400px ;"
      :class="calculatedMargin"
    >
      <slot name="tree"></slot>
    </el-aside>
    <el-main class="tree-page">
      <slot name="tree-page"></slot>
    </el-main>
  </el-container>
</template>

<script>
export default {
  props: {
    asideWidth: {
      type: String,
      default() {
        return "300px";
      },
    },
  },
  computed:{
    isRTL(){
      return this.$store.state.isRTL
    },
    calculatedMargin(){
      if(this.isRTL){
        return "u-left-margin--5x"
      }else{
        return 'u-right-margin--5x'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tree-page {
  padding: 0;
}
</style>
